<template>
	<section class="content">
		<div class="modal show" tabindex="-1" role="dialog" ref="formAffiliator" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Ajak Teman</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="mb-3 text-center" style="max-width: 500px;">
							<div class="w-100 d-flex justify-content-center">
								<img src="@/assets/img/affiliate-pana-min.png" class="w-50 h-100" />
							</div>
							<h5>Hujan Rejeki di Bulan Juni!</h5>
							<p class="text-gray">Dapatkan bonus BosPay sebesar 3% x ongkos kirim per paket yang teman kamu kirim! Bonus hingga Rp 5 Juta. Syarat dan ketentuan berlaku ya!</p>
							<div class="border rounded p-3">
								<span class="btn-group d-block mb-3 d-flex">
									<button class="btn btn-lg border rounded-left w-100">{{referralCode.toUpperCase()}}</button>
									<button class="btn btn-lg btn-primary" @click="copyCode"><span class="fa fa-copy"></span></button>
								</span>
								<div class="row">
									<div class="col-sm">
										<router-link to="afiliasi-shipper-detail" >
											<button class=" btn btn-lg btn-block btn-primary" data-dismiss="modal" >RIWAYAT</button>
										</router-link>
									</div>
									<div class="col-sm">
										<button class=" btn btn-lg btn-block btn-primary" @click="copyLink">AJAK TEMAN</button>
									</div>
								</div>
							</div>
						</div>
						
						<div>
							<label class="control-label">Teman yang sudah kamu ajak bergabung</label>
							<ul class="list-group">
								<li class="list-group-item" v-if="!referralList.length">Kamu belum berhasil ajak satu pun teman</li>
								<li class="list-group-item" v-for="affiliated in referralList">{{affiliated.nama}}</li>
							</ul>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { authFetch, auth } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
	name: "FormAffiliator",
	props: {
		show: Boolean,
	},
	data() {
		return {
			orderOptions: [],
			errors: [],
			categories: [],
			priorityOpt: [],
			method: "POST",
			selectedId: 0,
			defaultId: 1,
			addrOptions: [],
			addrJne: [],
			courierBranchOption: [],
			formTitle: "Buat Tiket",
			files: [],
			loaded: false,
			submitType: '',
			form: {
				//to create pickup address
				id: "",
				items: [],
				category: "",
				attachments: ""
			},
			referralList: [],
			referralCode: "",
			referralLink: "",
		};
	},
	components: {
	},
	created() {
		this.userInfo = auth.user();
	},
	methods: {
		copyCode: function(e){
			var self = this;
			navigator.clipboard.writeText(this.referralCode).then(function() {
				$(self.$refs.btnCopy).attr('title','Berhasil menyalin kode referal').tooltip('_fixTitle').tooltip('show').attr('title','Salin kode referal').tooltip('_fixTitle')
				Swal.fire("Berhasil mendapat kode referal", "Gunakan dengan cara <i>paste</i> atau tempel ke kolom Referal Teman di halaman pendaftaran", "success");
			}, function(err) {
				console.error(err);
			});
		},
		copyLink: function(e){
			var self = this;
			navigator.clipboard.writeText(this.referralLink).then(function() {
				$(self.$refs.btnCopy).attr('title','Berhasil menyalin link pendaftaran referal').tooltip('_fixTitle').tooltip('show').attr('title','Salin link pendaftaran referal').tooltip('_fixTitle')
				Swal.fire("Berhasil mendapat link pendaftaran", "Bagikan ke temanmu dengan cara <i>paste</i> atau tempel link", "success");
			}, function(err) {
				console.error(err);
			});
		},
	},
	mounted() {
		// console.log("warehouse1", this.value);
		const e = this.$refs;
		// if (this.show === true) $(e.formSelectPickup).modal("show");
		// console.log(this.show);
		$(e.formAffiliator).on("show.bs.modal", e => {
			if (!this.loaded) {
				this.loaded = true;
			}
		});

		$(e.formAffiliator).on("hide.bs.modal", e => {});
	},
	watch: {
		show: function (old, val) {
			authFetch("/users/profile/affiliated")
		  	.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
		  	})
		  	.then((js) => {
				if (js.success) {
					this.referralList = js.data;
					this.referralCode = js.referral_code;
					this.referralLink = js.url;
				} else {
					// Swal.fire("Kamu belum berhasil ajak satu pun teman", ``, "error");
				}
		  	});
			$(this.$refs.formAffiliator).modal("show");
		},
	}
};
</script>
<style scoped>
</style>